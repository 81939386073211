<template>
    <div class="bg-page">
        <van-nav-bar :border="false" :placeholder="true" :fixed="true" :title="$t('充值')" left-arrow
                     @click-left="onClickLeft" @click-right="onClickRight">
            <template #right>
                <!-- <span style="max-width: 100px;color: #FFFFFF;">{{$t('充值说明')}}</span> -->
            </template>
        </van-nav-bar>
        <div class="wrapper">
            <div class="quk-card">
                <div class="quk-item">
                    <div class="value">
                        {{user.balance}} <span class="text-gray" >{{$t('USDT')}}</span>
                    </div>
                    <div class="label text-gray">{{$t('钱包余额')}}</div>
                </div>
                <div class="quk-item" v-if="user.huilv > 0 && !isUsdt">
                    <div class="value">{{user.huilv}}</div>
                    <div class="label text-gray">{{$t('汇率')}}</div>
                </div>
            </div>
            <div class="wt-panel">
                <div v-if="selectItem" style="display: flex;justify-content: space-between;font-size: 12px;
                background-color: #2b3640;border-radius: 10px;padding: 10px;margin-bottom: 15px">
                    <div style="font-size: 16px;display: flex;align-items: center;">{{selectItem.title}}</div>
                    <div class="item-btn" @click="show_channel = true">{{$t('重新选择')}}</div>
                </div>
                <div class="wt-input_items">
                    <div class="hd">{{$t('金额')}}
                        (
                        <span v-if="isUsdt" style="color: #DD4A68;font-size: 12px">{{$t('USDT')}}</span>
                        <span v-else style="color: #DD4A68;font-size: 12px">{{$t('R$')}}</span>
                        )
                    </div>
                    <van-field v-if="isUsdt" :disabled="disabled" v-model="money" :placeholder="$t('请输入充值金额')" @input="changeMoney2" type="number"/>
                    <van-field v-else v-model="money" :disabled="disabled" :placeholder="$t('请输入充值金额')" @input="changeMoney2" type="digit"/>
                </div>
                <div class="tri-cell">
                    <div style="padding: 0 10px 10px 10px;color: #009d64;font-size: 14px" v-if="real_money > 0">
                        <div>
                            <span class="v-label">{{$t('充值金额')}}：</span>
                            <span class="lg-val" v-if="isUsdt">
                                {{money}} <span style="font-size: 12px">{{$t('USDT')}}</span>
                            </span>
                            <span class="lg-val" v-else>
                                {{money}} <span style="font-size: 12px">{{$t('R$')}}</span>
                                =
                                {{(money / user.huilv).toFixed(2)}} <span style="font-size: 12px">{{$t('USDT')}}</span>
                            </span>
                        </div>
                    </div>
                    <div class="desposit-select" >
                        <div class="col" v-for="(item,index) in money_list" :key="index">
                            <div class="desposit-card" :class="current == index?'active':''" @click="changeMoney(parseFloat(item),index)">
                                {{ item }}
                            </div>
                        </div>
                        <div class="col" >
                            <div class="desposit-card" :class="current == 99?'active':''" @click="clickOther">
                                {{ $t('其他金额') }}
                            </div>
                        </div>
                    </div>

                    <div class="robot-card-list" v-if="false">
                        <template v-for="(item, index) in robot_list">
                            <div class="rc-cell"
                                 v-if="item.level > 0"
                                 :key="index"
                                 @click="changeMoney(parseFloat(parseFloat(item.price) + parseFloat(item.min_money)))"
                            >
                                <div class="flex_bd">
                                    <div class="rc-title">{{$t(item.name)}} {{$t('价格')}}
                                        <span >{{parseFloat(item.price)}}</span>
                                        <span  style="font-size: 12px"> {{$t('USDT')}}</span>
                                    </div>
                                    <div class="text-gray">{{$t('启动资金')}}
                                        <span >{{parseFloat(item.min_money)}}</span>
                                        <span  style="font-size: 12px"> {{$t('USDT')}}</span>
                                    </div>
                                </div>
                                <!--                            <div class="item-btn" v-if="item.level == 0">Received</div>-->
                                <div class="item-btn">
                                    {{parseFloat(parseFloat(item.price) + parseFloat(item.min_money))}} {{$t('USDT')}}
                                    <!--                                <img src="../../assets/images/reusdt/hot.png" style="width: 12px" v-if="item.level == 2 || item.level == 4">-->
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
            <div class="btn-space">
                <van-button type="primary" block @click="recharge">{{$t('确认充值')}}</van-button>
            </div>
        </div>
        <van-popup v-model="show_channel" position="bottom" :close-on-click-overlay="false">
            <div class="bs-popup-dialog">
                <div class="bs-popup-head">
                    <div class="tt">{{$t('请选择充值方式')}}</div>
                </div>
                <div class="wt-panel">
                    <van-radio-group v-model="pay_type">
                        <van-cell-group>
                            <van-cell v-for="(item, index) in scene"
                                    :title="item.title"
                                    :key="index"
                                    center
                                    clickable
                                    @click="handleClickSelectRecharge(item)"
                            >
                                <template #icon>
                                    <img v-if="item.channel == 'local_usdt' || item.method == 'usdt'" src="../../assets/pictures/USDT.png"
                                        class="wt-icon"
                                        alt="">
                                    <img v-else src="../../assets/pictures/bank-icon.png" class="wt-icon" alt="">
                                </template>
                                <template #title>
                                    {{item.title}}
                                    <!-- <span style="color: red" v-if="item.method == 'usdt'">{{$t('推荐')}}</span> -->
                                </template>
                                <template #right-icon>
                                    <van-radio :name="item.key">
                                        <template #icon="props">
                                            <img class="img-icon" :src="props.checked ? activeIcon : inactiveIcon"/>
                                        </template>
                                    </van-radio>
                                </template>
                            </van-cell>
                        </van-cell-group>
                    </van-radio-group>
                </div>
            </div>
        </van-popup>

        <!-- url付款链接 -->
        <van-popup :overlay="true" position="bottom"
                   closeable close-icon="cross"
                   v-model="showPayPage"
                   @close="payPageClosed()"
                   :style="{ height: '100%' }"
        >
            <div style="font-size:16px; padding:12px 0; text-align:center;background-color: #2b3640; color: #fff">
                {{$t('充值中心')}}
                <van-loading v-if="!payPageLoaded"/>
            </div>
            <div style="text-align: left; height:100%;">
                <iframe :src="payUrl" width="100%" height="100%" frameborder="0" @load="payLoaded"
                        :style="{backgroundColor: payPageLoaded ? '#2b3640': 'unset' }"></iframe>
            </div>
        </van-popup>

        <van-popup :overlay="false" position="bottom"
                   closeable
                   v-model="showBankPage"
                   :style="{ height: '100%' }"
        >
            <div class="bs-popup-dialog">
                <template v-if="rechargeBank">
                    <div class="p-title-label" style="padding-top: 30px">{{$t('银行卡信息')}}</div>
                    <van-form class="bs-form">
                        <van-field readonly v-model="rechargeBank.bank" class="theme_5">
                            <template #label><span class="text-gray">{{$t('银行名称')}}</span></template>
                            <template #button>
                                <div class="btn-copy1 cb1" data-clipboard-action="copy" :data-clipboard-text="rechargeBank.bank" @click="copy('.cb1')">{{$t('复制')}}</div>
                            </template>
                        </van-field>
                        <van-field readonly v-model="rechargeBank.name" >
                            <template #label><span class="text-gray">{{$t('持卡人姓名')}}</span></template>
                            <template #button>
                                <div class="btn-copy1 cb1" data-clipboard-action="copy" :data-clipboard-text="rechargeBank.name" @click="copy('.cb1')">{{$t('复制')}}</div>
                            </template>
                        </van-field>
                        <van-field readonly v-model="rechargeBank.number" >
                            <template #label><span class="text-gray">{{$t('银行卡号')}}</span></template>
                            <template #button>
                                <div class="btn-copy1 cb1" data-clipboard-action="copy" :data-clipboard-text="rechargeBank.number" @click="copy('.cb1')">{{$t('复制')}}</div>
                            </template>
                        </van-field>
                        <van-field readonly v-model="rechargeBank.money" >
                            <template #label><span class="text-gray">{{$t('充值金额')}}</span></template>
                            <template #button>
                                <div class="btn-copy1 cb1" data-clipboard-action="copy" :data-clipboard-text="rechargeBank.money" @click="copy('.cb1')">{{$t('复制')}}</div>
                            </template>
                        </van-field>
                        <van-field readonly v-model="real_money"  v-if="real_money > 0">
                            <template #label><span class="text-gray">{{$t('到账金额')}}</span></template>
                            <template #input>
                                <span class="theme_5">{{real_money}}</span> <span style="font-size: 12px;padding-left: 10px;color: #c8c9cc"> {{$t('USDT')}}</span>
                            </template>
                            <template #button>
                                <div class="btn-copy1 cb1" data-clipboard-action="copy" :data-clipboard-text="real_money" @click="copy('.cb1')">{{$t('复制')}}</div>
                            </template>
                        </van-field>
                        <van-field v-if="real_money > 0" type="textarea" rows="3" :label="$t('充值提示')" disabled >
                            <template #input>
                                <div v-html="$t('充值提示1')" style="color: #c8c9cc"></div>
                            </template>
                        </van-field>
                        <div class="p-upload" style="margin-left: 10px">
                            <van-uploader v-model="fileList" deletable :max-count="1"
                                          :after-read="afterRead"/>
                        </div>
                        <div class="p-footer">
                            <van-button class="queren" round block type="primary" :disabled="loading" @click="handleClickSave">
                                {{$t('保存并提交')}}
                            </van-button>
                        </div>
                    </van-form>
                </template>
                <template v-else>
                    <div>
                        <van-empty :description="$t('暂无银行渠道')"/>
                    </div>
                </template>
            </div>
        </van-popup>

        <!-- USDT收款 -->
        <van-popup :overlay="false" position="bottom"
                   closeable close-icon="cross"
                   v-model="showUsdtPage"
                   :style="{ height: '100%'}"
                   style="background-color: #2b3640"
        >
            <template v-if="usdt_address">
                <div class="bs-popup-dialog">
                    <div style="height: 50px;width: 100%; font-size:18px;">
                        {{$t('充值')}}
                    </div>
                    <div >
                        <div class="bs-panel">
                            <div class="cur-card">USDT</div>
                        </div>
                        <van-tabs class="dep-usdt-tabs" v-model="usdt_active" @rendered="onChangeUsdtType">
                            <van-tab v-for="(address, type) in usdt_address" :key="`type${type}`" :title="type">
                                <template #title>
                                    <div class="text">{{type}}</div>
                                </template>
                                <div class="mabox">
                                    <div style="text-align: center">
                                        <div class="tk-wx" :id="`usdtQrcode${type}`"></div>
                                        <div class="code-value"
                                             style="padding-bottom: 10px;font-size: 14px;text-align: center">
                                            {{address}}
                                        </div>
                                        <van-button :data-clipboard-text="address"  data-clipboard-action="copy" @click="copy('.clipboardBtn')" type="primary" round
                                                    size="small" style="min-width: 80px"
                                                    class="clipboardBtn btn-copy">
                                            {{$t('复制')}}
                                        </van-button>
                                    </div>
                                </div>
                            </van-tab>
                        </van-tabs>
                        <div class="mabox mabox-form" style="text-align: left; margin-top: 20px;" v-if="real_money > 0">
                            <div class="usdt-card">
                                <span class="text-7a8">{{$t('到账金额')}}</span>
                                <br/>
                                <span class="text-251 font32">{{real_money}}</span>
                                <span class="text-251"> (USDT) </span>
                            </div>
                            <!-- <div class="mabox-end">
                                <div class="tt">{{$t('USDT充值提示')}}</div>
                                <div class="tips" v-html="$t('USDT充值提示1')"></div>
                            </div> -->
                            <div class="bs-form">
                                <van-form ref="formData">
                                    <van-field class="w-usdt" v-model="pay_account" :placeholder="$t('请输入打款钱包地址')">
                                        <template #label><span class="text-gray">{{$t('交易编码')}}</span></template>
                                    </van-field>
                                </van-form>
                            </div>
                            <div class="p-upload mrt15">
                                <van-uploader v-model="fileList" deletable :max-count="1"
                                              :after-read="afterRead"/>
                            </div>
                        </div>
                        <div class="mabox-space">
                            <van-button type="primary" :disabled="loading" @click="handleClickSave(1)" block>{{$t('保存并提交')}}
                            </van-button>
                        </div>
                    </div>
                </div>
            </template>
            <template v-else>
                <div>
                    <van-empty :description="$t('暂无USDT收款地址')"/>
                </div>
            </template>
        </van-popup>

        <van-popup v-model="show_tips" position="center"
                   :close-on-click-overlay="false"
                   style="width: 90%;border-radius: 10px;">
            <div class="bs-popup-dialog" style="padding: 20px;">
                <div class="text-blue" style="font-size: 14px;font-weight: bold;margin-bottom:10px;">{{$t('TIPS')}}:</div>
                <div v-html="tips_item.tips" style="font-size: 15px;"></div>
                <div style="padding:20px;text-align: center">
                    <van-button block @click="rechare_tips" type="primary" round>{{$t('确认充值')}}</van-button>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
    import Clipboard from "clipboard";
    import QRCode from "qrcodejs2";

    export default {
        components: {},
        data() {
            return {
                show_channel: true,
                show_tips:false,
                tips_item:{},
                select_usdt: false,
                curr: -1,
                money: '',
                money_list: [300, 5000, 10000, 20000, 50000, 100000],
                activeIcon: require('../../assets/pictures/duigouxiao.png'),
                inactiveIcon: require('../../assets/pictures/duigouxiao2.png'),
                scene: [],
                user: {},
                real_money: 0,
                loading: false,
                fileList: [],
                showBankPage: false,
                isUsdt: false,
                usdt_active: 0,
                showUsdtPage: false,
                usdt_address: {
                    erc: '6473892fdjknf',
                    omni: '3523523432'
                },
                showKmPage: false,
                rechargeBank: {},
                showPayPage: false,
                payUrl: "",
                file: "",
                selectItem: "",
                pay_type: "bank",
                payPageLoaded: false,
                loadingUser: false,
                qecode: {},
                pay_account: '',
                pay_mobile: '',
                robot_list:[],
                current:-1,
                disabled:true,
                othermoney:'',
                desposits:[
                    {
                        price:'50',
                    },{
                        price:'100',
                    },{
                        price:'200',
                    },{
                        price:'500',
                    },{
                        price:'1000',
                    }
                ],
            }
        },
        methods: {
            clickOther(){
                this.disabled = false
                this.money = ''
                this.current = 99
            },
            onClickRight() {
                this.$router.push({path: '/recharge'})
            },
            copy(val) {
                console.log(val);
                let clipboard = new Clipboard(val ? val : ".clipboardBtn");
                clipboard.on("success", (e) => {
                    this.$toast.success(this.$t('复制成功'));
                    // 释放内存
                    clipboard.destroy();
                });
                clipboard.on("error", (e) => {
                    this.$toast.success(this.$t('复制失败'));
                    // 释放内存
                    clipboard.destroy();
                });
            },
            onChangeUsdtType(e, type) {
                this.genQrcode('usdtQrcode' + type, this.usdt_address[type])
            },
            genQrcode(id, address) {
                let url = address;
                if (this.qecode[id]) {
                    this.qecode[id].clear();
                    this.qecode[id].makeCode(url);
                    setTimeout(() => {
                        let sq = window.document.getElementById(id);
                        let img = sq.querySelector('img');
                        let canvas = sq.querySelector('canvas');
                        if (img && canvas) {
                            img.style = "display:none !important";
                            canvas.style = "display:block !important";
                        }
                    }, 50);
                    return;
                }
                this.qecode[id] = new QRCode(id, {
                    text: url,
                    typeNumber: 17,
                    width: 128,
                    height: 128,
                    colorDark: "#000000",
                    colorLight: "#ffffff",
                    correctLevel: QRCode.CorrectLevel.H,
                });
                setTimeout(() => {
                    let sq = window.document.getElementById(id);
                    let img = sq.querySelector('img');
                    let canvas = sq.querySelector('canvas');
                    if (img && canvas) {
                        img.style = "display:none !important";
                        canvas.style = "display:block !important";
                    }
                }, 50);
            },
            payLoaded() {
                this.payPageLoaded = true;
            },
            payPageClosed() {
                console.log("payPageClosed");
                this.payUrl = "";
                this.payPageLoaded = false;
            },

            afterRead(file, ext) {
                this.file = file.file;
                console.log(this.file,'this.filethis.filethis.file');
                // helper.compressImg(file.file, (data) => {
                //     this.file = helper.dataURLtoFile(data);
                // });
            },
            handleClickSelectRecharge(item) {
                this.pay_type = item.key;
                this.selectItem = item;
                if (item.channel == 'local_usdt' || item.code == 'TRC20' || item.method == 'usdt') {
                    if (this.isUsdt != true) {
                        this.money = (this.money / this.user.huilv).toFixed(0);
                    }
                    this.real_money = this.money;
                    this.isUsdt = true;
                } else {
                    if (this.isUsdt == true) {
                        this.money = Math.ceil(this.money * this.user.huilv).toFixed(0);
                    }
                    this.isUsdt = false;
                    this.changeMoney2()
                }
                this.show_channel = false;
            },
            onClickLeft() {
                this.$router.push({path: '/'});
                // this.$router.go(-1);
            },
            changeMoney(money,index) {
                this.disabled = true
                this.current = index
                if (this.user.huilv > 0 && !this.isUsdt) {
                    this.money = Math.ceil(money * this.user.huilv).toFixed(0);
                    this.real_money = (this.money / this.user.huilv).toFixed(2);
                } else {
                    this.money = money;
                    this.real_money = this.money;
                }
            },
            changeMoney2(index) {
                this.real_money = this.user.huilv > 0 && !this.isUsdt ? (this.money / this.user.huilv).toFixed(2) : this.money;
            },
            getData() {
                this.$axios
                    .get(this.$api_url.user + "?type=2")
                    .then((res) => {
                        this.user = res.data;
                        this.money = res.data.r_default;
                        this.changeMoney2();
                    })
            },
            rechare_tips(){
                this.recharge();
                this.show_tips = false;
            },
            recharge() {
                if (this.money == undefined || this.money < 0 || this.money == '' || this.money.trim() == '') {
                    this.$toast.fail(this.$t('请输入充值金额'));
                    return;
                }
                let item = this.selectItem;
                console.log(item.min_amount,'min_amount')
                console.log(this.money,'money')
                if(item.min_amount > this.money){
                    this.$toast.fail(this.$t('最低充值金额为：{0}', [item.min_amount + '']));
                    return;
                }
                if(item.max_amount != 0 && item.max_amount < this.money){
                    this.$toast.fail(this.$t('最高充值金额为：{0}', [item.max_amount + '']));
                    return;
                }
                if (typeof item == "object") {
                    // if(this.show_tips == false && (item.tips != '' || item.tips.trim() != '')){
                    //     this.show_tips = true;
                    //     this.tips_item = item;
                    //     return ;
                    // }
                    if (item.channel == "local_bank" || item.channel == "local_wechat" || item.channel == "local_alipay") {
                        this.handleClickShowBank(item.setting, item);
                        return;
                    }else if (item.channel == "local_usdt") {
                        this.handleClickShowUsdt(item.setting, item);
                        return;
                    }
                } else {
                    this.$toast.fail(this.$t('请选择充值方式'));
                    return;
                }
                this.doRecharge()
            },
            doRecharge() {
                if (this.money == undefined || this.money < 0 || this.money == '' || this.money.trim() == '') {
                    this.$toast.fail(this.$t('请输入充值金额'));
                    return;
                }
                let param = new FormData();
                if (this.select_usdt == true) {
                    param.append("money", this.real_money);
                } else {
                    param.append("money", this.money);
                }
                param.append("file", this.file);
                param.append("pay_account", this.pay_account);
                param.append("pay_mobile", this.pay_mobile);
                param.append("pay_type", this.pay_type);

                // var tempwindow = null;
                let loading = this.$toast.loading(this.$t('加载中'));

                this.$axios
                    .post(this.$api_url.recharge, param, {
                        headers: {"Content-Type": "multipart/form-data"},
                    })
                    .then((responese) => {
                        let res = responese.data;
                        if (res.code === 0) {
                            let data = res.data;
                            if (!data.pay_url) {
                                this.$toast.success(res.msg);
                                return;
                            }
                            if(data.target_type == 0){
                                this.payUrl = data.pay_url;
                                this.showPayPage = true;
                                this.payPageLoaded = false;
                                loading.clear();
                            }else if(data.target_type == 1){
                                loading.clear();
                                let deviceAgent = navigator.userAgent;
                                let ios = deviceAgent.toLowerCase().match(/(iphone|ipod|ipad)/);
                                if(ios){
                                    document.location.href = data.pay_url;
                                }else{
                                    window.open(data.pay_url, '_blank');
                                }
                            }
                        } else {
                            console.log(res)
                            this.$toast.fail(res.msg);
                        }
                    })
                    .catch((err) => {
                        this.$toast.fail(err.message);
                        loading.clear();
                    }).then(() => {
                    this.showBankPage = false;
                    this.showUsdtPage = false;
                });
            },
            handleClickSave(f) {
                if (f == 1) {
                    // if (this.pay_account == '') {
                    //     this.$toast.fail(this.$t('请输入打款钱包地址'));
                    //     return;
                    // }
                    this.select_usdt = true;
                }
                if (this.file) {
                    this.doRecharge()
                } else {
                    this.$toast.fail(this.$t('请添加截图'));
                }
            },
            handleClickShowBank(item, pitem) {
                if(pitem.min_amount > this.money){
                    this.$toast.fail(this.$t('最低充值金额为：{0}', [pitem.min_amount + '']));
                    return;
                }
                if(pitem.max_amount != 0 && pitem.max_amount < this.money){
                    this.$toast.fail(this.$t('最高充值金额为：{0}', [pitem.max_amount + '']));
                    return;
                }
                if (item) {
                    this.rechargeBank.number = item.bank_number;
                    this.rechargeBank.name = item.name;
                    this.rechargeBank.bank = item.bank_name;
                    this.rechargeBank.ifsc = item.ifsc;
                    this.rechargeBank.branch = item.branch;
                } else {
                    this.pay_type = "bank";
                }
                this.rechargeBank.money = this.money;
                this.showBankPage = true;
            },
            handleClickShowUsdt(item, pitem) {
                if (pitem.min_amount > this.money) {
                    this.$toast.fail(this.$t('最低充值金额为：{0}', [pitem.min_amount + '']));
                    return;
                }
                if (pitem.max_amount != 0 && pitem.max_amount < this.money) {
                    this.$toast.fail(this.$t('最高充值金额为：{0}', [pitem.max_amount + '']));
                    return;
                }
                this.showUsdtPage = true;
                this.usdt_address = item;

            },
            getScene() {
                this.$axios
                    .get(this.$api_url.get_recharge_scene + "?money=" + this.money)
                    .then((res) => {
                        if (res.data) {
                            this.scene = res.data.list;
                            this.robot_list = res.data.robot_list;
                        }
                    });
            },
        },
        mounted() {
            this.getData();
            this.getScene();
            let recharge_default_amount = localStorage.getItem('app.recharge_default_amount');
            console.log(recharge_default_amount)
            if (recharge_default_amount) {
                this.money_list = recharge_default_amount.split(',')
                console.log(this.money_list)
            }
        },
        // watch:{
        //     money(n, o){
        //         this.money = n.replace("-", "");

        //     }
        // }
    }
</script>
<style>
    .w-usdt .van-field__label{
        width: 100%;
    }
    .p-upload .van-uploader__upload{
        background-color: #2b3640;
        border-radius: 5px;
    }
</style>
<style scoped>
    .desposit-select{
        display: flex;
        flex-wrap: wrap;
        margin: 0 -6px;
    }
    .desposit-select .col{
        width:50%;
        box-sizing: border-box;
        padding: 6px;
        text-align: center;
    }
    .desposit-card{
        display: flex;
        align-items: center;
        justify-content: center;
        background:#2b3640;
        border-radius: 10px;
        height: 44px;
        font-size: 14px;
    }
    .desposit-card.active{
        color: #fff;
        background-color: #3d8cc6;
    }
    .desposit-card .van-cell{
        background-color: transparent;
        color: #FFFFFF;
    }
    .desposit-card ::v-deep .van-field__control {
        color: #FFFFFF;
        text-align: center;
    }
    .desposit-card ::v-deep .van-field__control::placeholder {
        color: #333;
    }
    .desposit-card.active ::v-deep .van-field__control::placeholder {
        color: #FFFFFF;
    }
    .tk-wx {
        margin: 15px auto;
    }

    .tk-wx >>> img, .tk-wx >>> canvas {
        width: 180px;
        margin: 0 auto;
        border: 1px solid #000;
        padding: 10px;
        border-radius: 5px;
    }

    .van-tab--active .text {
        color: #FFFFFF;
    }

    .item-btn{
        width: 100px;
        text-align: center;
        padding: 10px 0;
        border-radius: 10px;
        font-size: 14px;
        color: #131d26;
        background-color: #56d6f1;
    }
</style>